.modal {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 1000;

  &BackDrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #090909;
    opacity: 0.2;
  }
}

.mobileModal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: to-rem(310);
  background-color: var(--color-white);
  border-top-left-radius: to-rem(15);
  border-top-right-radius: to-rem(15);
  padding-top: to-rem(30);

  &::before {
    content: '';
    width: to-rem(50);
    height: to-rem(5);
    background-color: #dee6f2;
    display: inline-block;
    position: absolute;
    top: to-rem(15);
    left: 50%;
    transform: translateX(-50%);
    border-radius: to-rem(10);
  }
}

.desktopModal {
  position: absolute;
  top: 84px;
  left: 50%;
  bottom: 84px;
  background-color: var(--color-white);
  overflow-y: auto;
  transform: translateX(-50%);
  box-shadow: 0 2px 20px 0 rgba(212, 212, 212, 0.5);
  max-width: 80vw;
  width: 500px;

  &Header {
    font-size: to-rem(25);
    color: #131415;
    border-bottom: to-rem(1) solid #f0f4f9;
    padding: to-rem(35 50 20);
    position: sticky;
    background-color: var(--color-white);
    top: 0;

    svg {
      display: none;
    }
  }
}

.center {
  left: 50%;
  top: 50%;
  bottom: unset;
  transform: translate(-50%, -50%);
  border-radius: to-rem(5);
  overflow: hidden;
  min-height: to-rem(70vh);
}
