@import '../../../styles/breakpoint';

.dashboardItemTagValid {
  background-color: var(--color-success-light);
  color: var(--color-success-dark);
}

.dashboardItemMenu {
  color: inherit;
  display: inline-block;
  padding: 15px 10px;
}

.welcomeBlock {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.noWrap{
  white-space: nowrap;
}

.copyContainer {
  margin: 5% 0;
}
.copyContainer h1{
  font-size: 3.4rem;
  text-align: center;
  font-weight: 800;
  color: var(--color-primary);
  margin: 0;
  line-height: 3rem;
  margin-top: 1rem;
}
.copyContainer p {
  font-size: 1.2rem;
  color: var(--color-primary);
  white-space: nowrap;
}
.welcomeBlock button{
  font-size: 3rem;
  width: 20rem;
  height: 6rem;
  font-weight: 700;
  color: var(--color-background);
}
.welcomeBlock p {
  text-align: center;
  margin: 10px 0;
}

.icon {
  font-size: 3em;
  color: var(--color-primary);
}

.itemsView {
  text-align: left;
  padding: 20px;
}
.itemsView .AddItemBtn{
  color: var(--color-white);
}

.itemsView h4 {
  color:var(--color-primary);
  font-size: 24px;
  margin-top: 0;
}

@media (max-width: $screen-md-min) {
  .itemsView {
    padding: 0;
  }

  .itemsView h4 {
    //  TODO: Apply proper specificity. Keeping this only for internal review.
    display: none !important;
  }

  .welcomeBlock {
    width: 100%;
    margin-top: 0;
  }
}
