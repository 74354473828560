.pagination {
    display: flex;
    justify-content: center;
  }
  
  .pageNumbers {
    display: flex;
  }
  
  .item {
    padding: 0 5px;
  }
  
  .presentPage {
    font-weight: 500;
  }
  