@import '../../styles/generic.scss';

.confirmationBox {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-white);
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 20px 0 rgba(212, 212, 212, 0.5);
  max-width: 481px;
  width: 100%;
  padding: 24px;
  border-radius: 4px;

  &Actions {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0 0;

    @include md {
      flex-direction: row-reverse;
    }
  }

  &Content {
    color: var(--color-base-font);
    font-size: 16px;
    line-height: 1.43;
    padding-bottom: 30px;
  }

  &Title {
    font-size: 24px;
    margin-top: 0;
  }
}

.actionButton {
  width: 100%;

  @include md {
    width: 168px;
  }
}

.submitButton {
  @extend .actionButton;

  @include sm {
    margin-top: 16px;
  }
}

.cancelButton {
  @extend .actionButton;
  border: 1px solid var(--color-base-font);
  color: var(--color-base-font);
  background-color: transparent;

  @include md {
    margin-right: 16px;
  }
}
