@import '../../styles/generic.scss';

.messageContent {
  display: flex;
  align-items: center;
  background-color: var(--color-success-background);
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 4px;
}

.messageContent span {
  margin: 0 20px;
  background: var(--color-white);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid var(--color-success-dark);
}

.messageContentHeading {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-success-dark);
}

.messageContentHeading div {
  text-align: left;
  margin: 0;
  padding-left: 0;
}

.messageContentSubHeading {
  font-size: 12px;
  color: var(--color-success-dark);
}

.actionButton {
  width: 100%;

  @include md {
    width: 168px;
  }
}

.submitButton {
  @extend .actionButton;

  @include sm {
    margin-bottom: 16px;
  }

  @include md {
    margin-right: 16px;
  }
}

.formActions {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid var(--color-pale-blue);
  padding: 20px 0;

  @include md {
    flex-direction: row-reverse;
  }
}

.cancelButton {
  @extend .actionButton;
  border: 1px solid var(--color-grey-04);
  color: var(--color-base-font);
  background-color: transparent;
}
