@import '../../styles/generic.scss';

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.label {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 12px;
  color: var(--color-base-font);

  @include sm {
    font-weight: 500;
  }
}

.input {
  border: none;
  border-radius: 4px;
  background: var(--color-pale-blue);
  height: 50px;
  padding: 0 10px;
}

.message {
  border-radius: 2px;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 10px 5px;
  color: var(--color-white);
  text-align: center;
}

.message-error {
  background-color: var(--color-error);
}

.message-success {
  color: var(--color-success-dark);
  background-color: var(--color-success-background);
}

.passwordField {
  bottom: 0;
  color: var(--color-black);
  cursor: pointer;
  line-height: 1.5em;
  padding: 5px 10px;
  position: absolute;
  right: 0;
}

.fileInput {
  display: flex;
  flex-wrap: wrap;
}

.fileContainer {
  align-items: center;
  border: 1px dashed var(--color-primary);
  border-radius: 4px;
  color: var(--color-primary);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 48px;
  justify-content: center;
  line-height: 48px;
  overflow: hidden;
  position: relative;
  text-align: center;

  &:not(.fileContainerWithUploadedFiles) {
    flex: 1;
  }

  & > svg {
    margin-right: 10px;
    vertical-align: middle;
  }
}

.fileContainer [type='file'] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.fileList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.fileContainerWithUploadedFiles,
.fileListItem {
  $dimensions: 96px;

  height: $dimensions;
  margin: 10px 10px 0 0;
  text-align: center;
  width: $dimensions;
}

.fileListItem {
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  position: relative;

  img {
    max-width: 100%;
    object-fit: cover;
  }
}

.fileListItemLabel {
  flex: 1;
}

.fileListItemDeleteIcon {
  $dimensions: 20px;

  align-items: center;
  background-color: var(--color-white);
  border: 1px solid #dfdfdf;
  border-radius: $dimensions/2;
  display: flex;
  height: $dimensions;
  justify-content: center;
  position: absolute;
  right: -$dimensions/2;
  top: -$dimensions/2;
  width: $dimensions;

  svg {
    height: $dimensions - 4px;
  }
}

.selectContainer {
  @extend .input;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjkwLjEzMywyOS43NTcgNTAsNjkuODkgOS44NjcsMjkuNzU3ICI+PC9wb2x5bGluZT48L3N2Zz4');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  color: var(--color-base-font);
}

.selectContainer::-ms-expand {
  display: none;
}

.selectContainer:hover {
  border-color: #888;
}

.selectContainer:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.selectContainer option {
  font-weight: normal;
}

.currencyContainer {
  display: flex;
  flex-grow: 1;
}

.currencyLogo {
  background-color: #d3d8d9;
  color: var(--color-base-font);
  width: 60px;
  line-height: 48px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.currencyInput {
  @extend .input;
  width: 100%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}