@import "../../styles/breakpoint";

.container {
  background: var(--color-primary);
  color: var(--color-white);
  padding: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.AppIcon {
  height: 40px;
}
.value {
  font-size: 48px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-background);
}

.value span {
  font-size: 16px;
  font-weight: 100;
  color: var(--color-white);
}

.counterBox {
  display: flex;
  font-size: 16px;
  flex: 3;
  justify-content: center;
}

.counter {
  display: flex;
  align-items: center;
}

.counter:first-child {
  margin-right: 16px;
}

.counter .image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}

.counter .circle {
  opacity: 0.5;
  color: var(--color-black);
}

.counter .icon {
  position: absolute;
  color: var(--color-background);
  width: 24px;
}

.addItemBox {
  flex: 1;
}

.button {
  padding: 1rem;
}

.itemPrimary {
  background-color: var(--color-background);
  color: var(--color-primary);
  font-size: 32px;
  font-weight: 800;
}

@media (max-width: $screen-md-min) {
  .container {
    flex-direction: column;
    align-items: stretch;
    border-radius: 0;
  }

  .value {
    align-items: center;
  }

  .counterBox {
    margin: 16px auto;
  }
}
