.item {
  display: inline-flex;
  font-size: 10px;
  border-bottom: 1px solid var(--color-list-item-border);
  background: var(--color-white);
  padding: 16px 0;
  width: 100%;
  box-shadow:  1px 1px 2px 1px rgba(253, 209, 206, 0.16);
  border: 1px solid var( --border-list-item);
  border-bottom: 0px;
}

.item.active {
  background: var(--color-grey-06)
}

.backgroundGrey {
  background-color: var(--color-list-item-active);
}

.backgroundWhite {
  background-color: var(--color-white);
}

.paddingAdd {
  padding: 10px 20px;
}

.select {
  display: flex;
  align-items: center;
  margin: auto 16px;
}

.select .button {
  height: 16px;
  width: 16px;
  border-radius: 2px;
}

.item.active .select button {
  background: var(--color-base-font);
  color: var(--color-white);
}

.image {
  display: flex;
  margin-right: 12px;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--border-list-item);
}

.image span {
  display: flex;
  height: 80px;
  width: 80px;
  background: var(--color-image-white);
  border-radius: 2px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}

.details {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  line-height: 1.8em;
  flex-direction: row;
}

.name {
  display: flex;
  flex: 1;
  font-weight: 700;
  font-size: 24px;
  color: var(--color-primary);
}

.date {
  display: inline;
}

.value {
  display: flex;
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-success-dark);
}

.extra {
  font-size: 14px;
  color:var(--color-base-font);
}

.more {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
}

.more .button {
  border: none;
  background: none;
  padding: 0;
}

.menuShow {
  display: block;
}

.menuHide {
  display: none;
}

.actionList {
  right: 50px;
}

.detailColumn {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

@media (max-width: 768px) {

  .name {
    display: flex;
    flex: 1;
    font-size: 14px;
  }

  .select {
    margin: 12px 12px auto;
  }

  .item {
    margin-bottom: 8px;
    min-height: 100px;
  }

  .image span {
    height: 64px;
    width: 64px;
  }

  .detailsContainer {
    margin: 0;
  }

  .value {
    font-size: 12px;
  }

  .more {
    margin-left: 10px;
  }

  .more .actionList {
    padding: 0;
    right: 0;
  }
}
