.container {
  text-align: center;
  margin: 0;
  width: 944px;
}
.Story-container {
  text-align: center;
  margin: 2rem 0;
  width: auto;
}
.Story-hero {
  height: 500px;
  display: flex;
  padding: 2rem 0;
  margin-bottom: 1rem;
  max-width: 960px;
  margin: auto;
}

.Summary {
  padding-right: 40px;
}

.Summary h2 {
  font-size: 148px;
  font-weight: 600;
  line-height: 0.9;
  color: var(--color-primary);
  text-align: left;
}
.Summary .highlight {
color:var(--color-white);
}
.Summary h4 {
  font-size: 32px;
  font-weight: 600;
  line-height: 0.9;
  color: var(--color-primary);
  text-align: left;
  margin:0 0 0.5rem 0;
}

.subtitle{
  margin-bottom: 2rem;
}

.Summary p {
  text-align: left;
  font-size: 14px;
  line-height: 1.71;
  color: var(--color-primary);
}
.sImage img {
  width: 400px;
}

.Ourstory {
  display: flex;
  align-items:flex-end;
  justify-content: space-between;
  height: 500px;
  padding: 2rem;
  margin-bottom: 35px;
  width: 1024px;
  margin: auto;
  background: url(../../../assets/images/aboutus-backgound.jpg) no-repeat;
  background-size: cover;
  background-position: bottom;
}
.ourstoryButton {
  display: inline-block;
  width:200px;
  margin-right:1rem;
} 
.mainButton {
  background-color: var(--color-white);
  color: var(--color-primary);
}
.subButton
{
  background-color: var(--main-background);
  color: var(--color-primary);
}
.Ourstory h2 {
  width: 320px;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color:var(--color-white);
}
.Ourstory h2:after {
  content: '';
  display: block;
  margin: 0 left;
  width: 50%;
  padding-top: 0.5rem;
}
.Gottabe{
  width: 200px;
}
.Ourstory p {
  width: 482px;
  height: 426px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--color-text-dark);
}
.Suresure-owners {
  height: 100%;
  padding-top: 6%;
  padding: 2rem;
  max-width: 1024px;
  margin: auto;
  align-items: left;
}
.Team-suresure-gallery {
  display: flex;
  flex-wrap: wrap;
}
.Team-suresure-gallery img {
  width: 304px;
}
.Suresure-owners h2 {
  height: 32px;
  margin-bottom:1rem;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--color-primary);
}

.Suresure-owners p {
  width: 200px;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: var(--subtitle);
}

.Suresure-owners h3 {
  width: 304px;
  font-size: 20px;
  font-weight:500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--color-grey-05);
}

.Suresure-owners h2:after {
  content: '';
  display: block;
  margin: 0 left;
  width: 50%;
  padding-top: 0.5rem;
}

.Team-suresure-gallery > div {
  margin: 0 1rem 2rem 0;
  align-items: flex-start;
}

.Description {
  -webkit-column-count: 2;
  /* Old Chrome, Safari and Opera */
  -moz-column-count: 2;
  /* Old Firefox */
  column-count: 2;
  column-gap: 2rem;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--color-text-dark);
}

@media (max-width: 768px) {
  .Story-hero {
    flex-direction: column;
    margin: 0 1.5rem;
    height: 100%;
    padding: 0;
    text-align: center;
    width: auto;
  }
  .Summary h2 {
  font-size: 104px;
}
  .subtitle {
    margin-top: 2rem;
  }
  .subtitle h4 {
    text-align: center;
  }
  .Summary {
    padding-right: 0;
  }

  .Summary h2 {
    text-align: Center;
  }

  .Summary p {
    text-align: center;
  }
  .Description {
    display: flex;
    justify-content: center;
  }
  
  .Suresure-owners {
    width: auto;
    padding: 40px 55px 0;
    margin-bottom: 35px;
    margin: auto;
  }

  .Team-suresure-gallery > div {
   margin: 0 0.5rem 2rem 0;
  }

  .Ourstory {
    width: auto;
    padding: 2rem;
    flex-direction: column;
  }

  .Description {
    display: flex;
    justify-content: center;
  }

  .Suresure-owners {
    padding: 2rem;
    margin-bottom: 35px;
    margin: auto;
  }
  .Suresure-owners p{
    text-align: left;
  }
  .Team-suresure-gallery img {
    width: 224px;
}

.ourstoryButton 
{
  margin-bottom: 1rem;
}




}

@media (max-width: 360px) {
  .Suresure-owners {
    width: 220px;
    padding: 0;
    margin: 2rem;
  }

  .Team-suresure-gallery img {
    width: 224px;
  }
}
