.filterList {
  background: var(--color-white);
  position: absolute;
  right: 0;
  z-index: 999;
  font-size: 14px;
  min-width: 168px;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(212, 212, 212, 0.5);

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    background: var(--color-white);
  }
}

.filterItems button {
  display: flex;
  justify-content: flex-start;
  background: var(--color-white);
  width: auto !important;
  margin-left: 0 !important;
}

.icons {
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .filterList {
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .icons {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
