:root {
  --color-accent: #ffdfdd;
  --color-accent-01: #ffcce8;
  --color-white: #ffffff;
  --color-black: #121212;

  --color-primary: #003D29;
  --color-secondary: white;
  --color-background: #FEDFDD;
  
  --color-base-font: #5a5a5a;
  --color-secondary-font: #a6a6a6;

  --color-text-light: #959595;
  --color-text-dark: #46516a;

  --color-grey-01: #393939;
  --color-grey-02: #f5f5f5;
  --color-grey-03: #e9eced;
  --color-grey-04: #d8d8d8;
  --color-grey-05: #262626;
  --color-grey-06: #dddddd;
  --color-grey-07: #f4f4f4;

  --color-success: #a5ba93;
  --color-success-light: #cbf4c9;
  --color-success-dark: #0d6345;
  --color-success-background: #ddf8dc;

  --color-error: #ee6e73;
  --color-error-light: #fce0e2;
  --color-error-dark: #661340;
  --color-cancel: #d90e0e;

  --color-lost-light: #f1f1f1;
  --color-lost-dark: #4f4f4f;

  --color-btn-border: rgba(215, 215, 215, 0.5);
  --color-image-placeholder: #dedede;
  --color-list-item-border: #eff2f2;
  --color-list-item-active: #f5f5f5;

  --color-pale-blue: #e3e7e8;

  --list-background: #F7EDED;
  --main-background: #fedfdd;
  --list-stripe-background: #E6E7E8;
  --badge-insured-sucess:#9CDBBD;
  --subtitle:#009b5c;
  --sidemenu-selected:#F7EDED;
  --title-bar:#013021;
  --border-list-item:#a6a8ab;
}
