.container {
  padding: 5px 16px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 10px;

  span {
    font-weight: bold;
  }

  svg {
    margin-right: 5px;
  }
}

.success {
  color: var(--color-primary);
  background:var(--badge-insured-sucess);
}
.success svg {
  color: var(--color-secondary);
}


.error {
  color: var(--color-error-dark);
  background: var(--color-error-light);
}

.lost {
  color: var(--color-lost-dark);
  background: var(--color-lost-light);
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
    margin-right: 0;
    height: 20px;
  }

  .lost {
    position: absolute;
    right: 0;
    top: -28px;
  }
}
