.button {
  align-items: center;
  background-color: #000;
  border: none;
  border-radius: 2px;
  color: rgba(255, 255, 255, .86);
  display: flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  transition: opacity .3s ease-in-out;
  width: 100%;
}

.button:focus,
.button:hover {
  opacity: .7;
}