.button {
  align-items: center;
  border: none;
  border-radius: 4px;
  display: flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  cursor: pointer;
}

.button:hover,
.button:disabled {
  opacity: 0.7;
}

.primary {
  background-color: var(--color-primary);
  color: rgba(255, 255, 255, 0.86);
}
.itemPrimary {
  background-color:var(--color-background);
  color: var(--color-primary);
}

.secondary {
  background-color: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-btn-border);
}

.info {
  background-color: rgba(222, 222 ,222, 0.3);
  color: var(--color-base-font);
}
