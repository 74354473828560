.wrapper {
  background-color: var(--color-background);
  color: var(--color-base-font);
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  min-height: 100vh;
}

.wrapper>main {
  flex: 1;
  padding: 40px 20px;
}

.button {
  background-color: lightgray;
}