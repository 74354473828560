@import '../../styles/generic.scss';

.list {
  display: flex;
  flex-direction: column;
  background: var(--color-white);
}
.span {
  font-size: 24;
}
.row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-list-item-border);
}

.header {
  background-color: var(--color-pale-blue);
  font-weight: Normal;
  font-size: 14px;
}
.badgeContainer svg {
      display: none;
    }
.item {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  line-height: 1.5em;
  padding: 1rem 0;
  flex-grow: 4;
  flex-basis: 0;
  padding-left: 20px;
  align-items: center;
}

.item.email {
  flex-grow: 4;
  flex-basis: 0;
}

.item.action {
  flex-grow: 3;
  flex-basis: 0;
  padding-right: 1rem;
  flex-direction: row-reverse;
}

.icon {
  margin: 0 5px 0 0;
  background: none;
  color: var(--color-base-font);
  width: auto;
  height: auto;
}
.button {
  margin: 0 10px 0 0;
  padding: 10px 12px;
  background: none;
  color: var(--color-base-font);
  width: auto;
  height: auto;
  cursor: pointer;
  border: 2px solid var(--color-primary);
}

.icon:disabled {
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .row {
    flex-direction: row;
  }
  .inviteBtn {
    display: none;
  }
  .Account.btnBar {
    display: none;
  }
  .row {
    border-bottom: 1px solid var(--color-list-item-border);
  }
  .email {
    display: none;
  }
  .badgeContainer {
    $dimension: 6px;
    border-radius: $dimension/2;
    height: $dimension;
    margin-top: 1.1rem;
    padding: 0;
    width: $dimension;
    flex-grow: 1.5;

    span,
    svg {
      display: none;
    }
  }
}

.editInviteHeader {
  display: none;

  @include md {
    font-size: 18px;
    font-weight: 500;
    color: #181818;
    padding: 24px 0 20px;
    border-bottom: 1px solid var(--color-pale-blue);
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
  }
}

.editInvite {
  padding: 0 24px;
}

.formActions {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid var(--color-pale-blue);
  padding: 20px 0;

  @include md {
    flex-direction: row-reverse;
  }
}

.actionButton {
  width: 100%;

  @include md {
    width: 168px;
  }
}

.submitButton {
  @extend .actionButton;

  @include sm {
    margin-bottom: 16px;
  }

  @include md {
    margin-right: 16px;
  }
}

.cancelButton {
  @extend .actionButton;
  border: 1px solid var(--color-grey-04);
  color: var(--color-base-font);
  background-color: transparent;
}
