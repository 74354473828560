@import './normalize.scss';
@import './variables.scss';

* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-background);
  font-family: 'Prompt', sans-serif;
}

h2 {
  font-size: 4.5em;
  line-height: 1;
  margin: 0;
}

h3 {
  font-size: 2em;
  margin: 0;
}

/* Generic Transition */
.generic-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}

.generic-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.generic-transition-exit {
  opacity: 1;
}

.generic-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  outline: none;
}

.loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  svg {
    height: 200px;
    stroke: currentColor;
  }
}