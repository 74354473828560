@import '../../styles/breakpoint';

.forgotPassword {
  color: var(--color-primary);
  display: inline;
  margin-bottom: 20px;
  font-size: 12px;
  opacity: 0.8;
  text-align: right;
  text-decoration: none;
}

.passwordField {
  display: flex;
  justify-content: space-between;
}

.green {
  color: var(--color-primary);
}

.cta {
  align-content: center;
  text-align: center;
}

.cta a {
  text-decoration: none;
}

.grayBtn {
  background: var(--color-grey-03);
  color: var(--color-base-font);
  font-weight: bold;
}

.ctaTitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}

.ctaText {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin: 20px 0;
}

.container {
  width: 371px;
  background: var(--color-white);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  box-shadow: 0 17px 20px 0 rgba(221, 224, 226, 0.38);
}

.line {
  margin-top: 10%;
  border: 0.5px solid var(--color-pale-blue);
}


.loginHeading {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
  height: 24px;
  margin-top: 5%;
  margin-bottom: 2.5%;
  text-align: center;
}

.verifyHeading { 
  font-size: 24px;
  font-weight: bold;
  color: var(--color-black);
  margin-bottom: 0.5rem;
  text-align: center;
  display: inline-block;
  padding-bottom: 1.5rem;
  border-bottom: 0.2rem solid var(--color-grey-03);
}

.backBtn {
  display: none;
}

.dispatchMessage {
  text-align: center;
}

.loginBtn {
  width: 20rem;
  margin: 0 auto;
}

.btnWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: $screen-md-min) {
  .loginHeading {
    display: none;
  }

  .backBtn {
    display: block;
    height: 100%;
    width: auto;
    padding-left: 8%;
    padding-top: 7px;
    padding-bottom: 7px;
    color: var(--color-white);
  }
}

@media (max-width: $screen-sm-min) {
  .container {
    width: 100%;
  }
}

.fullscreenWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  min-height: 60vh;
}