@import "../../styles/generic.scss";

.container {
  margin: 16px 0;

  @include sm {
    margin: 16px;
  }
}

.buttonGroup {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .actions,
  .filters {
    position: relative;
  }

  .actions {
    flex: 1;

    & button {
      margin-left: 0;
    }
  }

  button {
    width: 168px;
    height: 40px;
    font-size: 14px;
    margin-left: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--border-list-item);
    border-radius: 0px;

    & > svg {
      pointer-events: none;
    }
  }
}
.buttonGroup .actions li button {
  border: none;
}
.buttonGroup .filters li button {
  border: none;
}

button.active {
  background: var(--color-base-font);
  color: var(--color-white);
}

.sortIcon {
  position: relative;
  height: 22px;
  width: 24px;

  .sort {
    position: absolute;
    left: 0;
  }

  .arrow {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 768px) {
  .buttonGroup {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .actions,
    .filters {
      position: unset;
    }

    .actions {
      flex: unset;
    }

    button {
      margin-right: 0;
    }
  }
}


@media (max-width: 560px) {
  .buttonGroup {
    button {
      width:100px;
    }
  }
}

