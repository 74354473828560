@import '../../styles/breakpoint';

.wrapper 
{
  background-color: var(--color-primary);
  padding: 2rem 0;
  border-top: 20px solid transparent;
  border-image: url(../../../src/assets/images/primary-border.png) 50 round;
  background-clip: padding-box;


}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: $screen-xl-min;
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

a {
  color: var(--color-background);
}

 a:hover {
  text-decoration: underline;
}

 .container .links {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.container .links li {
  display: inline-flex;
  margin: 0.5rem;
  font-size: 14px;
  color: var(--color-primary);
  justify-content: center;
  align-items: center;
}

.copyright {
  font-size: 14px;
  color: var(--color-background);
}

.socialMedia li {
  a {
    color: var(--color-primary);
    padding-top: 3px;
  }

  background-color: var(--color-background);
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

@media (max-width: $screen-md-min) {
  .container {
    flex-direction: column;
    max-width: $screen-md-min;
  }
  .copyright {
    margin: 20px 0;
  }
}