.sideMenu {
  height: 100%;
  background: rgba(246, 248, 249, 0.98);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  max-width: 400px;
  z-index: 1;
  transform: translateX(-100%);
  padding: 60px 16px 0;
  transition: transform 0.5s ease-out;
}

.sideMenu.open {
  transform: translateX(0);
}

.sideMenu ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.AppIcon{
  height:40px;
}
.sideMenu li {
  margin-bottom: 5px;
}

.selected {
    background-color: var(--list-background);

  //  TODO: Apply proper specificity. Keeping this only for internal review.
  color: var(--color-primary) !important;
}

.inActiveLink {
  //  TODO: Apply proper specificity. Keeping this only for internal review.
  color: #e6e6e6 !important;
  pointer-events: none;
}

.link {
  padding: 10px 0;
}

.sideMenu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.3s;
  
  font-size: 16px;
  padding: 16px 17px 16px;
  border-radius: 4px;
  color: var(--color-base-font);
  text-align: left;
}
.sideMenu a .icon {
  margin-right: 15px;
}

.sideMenu li:hover,
.sideMenu li:active {
  color: #000000;
}

button.closeNav {
  position: absolute;
  top: 5%;
  right: 10%;
  font-size: 17px;
  font-weight: bold;
  margin-left: 50px;
  border-bottom: 0;
  background: none;
  border-style:none; 
  color: var(--color-base-font);
}
