.container {
  text-align: center;
  width: 100%;
  margin: auto;
  display: flex;
  //  TODO: Apply proper specificity. Keeping this only for internal review.
  padding: 0 !important;
}

.columnRight {
  width: 100%;
  display: inline;
}

.columnLeft {
  width: 80px;
  display: inline-flex;
  flex-direction: column;
}

.sideMenu {
  top: 71px;
  width: 80px;
  padding: 20px 16px 0;
  background: white;
  height: 100%;
}

.sideMenu ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.sideMenu li {
  width: 100%;
}

.sideMenu a {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-base-font);
}

.sideMenu a .label {
  display: none;
}

.itemsView {
  text-align: left;
  padding: 20px;
}

.itemsView h4 {
  font-size: 24px;
  margin-top: 0;
  color: var(--color-primary)
}

@media (max-width: 768px) {
  .main {
    //  TODO: Apply proper specificity. Keeping this only for internal review.
    padding: 0 !important;
  }

  .columnLeft {
    display: none;
  }

  .itemsView {
    padding: 0;
  }

  .itemsView h4 {
    //  TODO: Apply proper specificity. Keeping this only for internal review.
    display: none !important;
  }
}