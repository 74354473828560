@import '../../styles/breakpoint';

.invite h1 {
  text-align: center;
}

.tableContainer {
  padding: 20px;
}

.btnBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  h4 {
    font-size: 24px;
    color: var(--color-primary);
    margin: 0;
  }
}

.inviteBtn {
  padding: 0 40px;
  flex-basis: 0;
  white-space: nowrap;
}

.account {
  margin: 0 3vw;
  text-align: left;
}

.accountTitle {
  color: #000;
  font-size: 24px;
}

.accountCard {
  background-color: var(--color-white);
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(215, 215, 215, 0.5);
  margin-bottom: 30px;
}

.accountCardContent {
  width: 100%;
  padding: 30px 2vw;
  max-width: 416px;
}

.accountCardTitle {
  color: var(--color-black);
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 20px;
}

@media only screen and (max-width: $screen-md-min) {
  .btnBar {
    justify-content: flex-end;
    h4 {
      display: none;
    }
  }
}