@import '../../styles/breakpoint';

.displayLoggedIn,
.displayLoggedOut {
  display: none;
}

@media (max-width: $screen-md-min) {
  .toggleMenu {
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--color-primary);
  }

  .toggleMenu:focus,
  .toggleMenu:hover {
    outline: none;
    opacity: 0.7;
  }

  .displayLoggedIn {
    display: block;
  }

  .displayLoggedOut {
    display: none;
  }
}