@import '../../styles/generic.scss';

.titleBar {
  color: var(--color-background);
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  background-color: var(--title-bar);
  margin-bottom: 5%;

  & > svg {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
  }
}

.sideMenu {
  top: 71px;
  width: 80px;
  padding: 0;
  background:var(--color-background);
  height: 100%;
}

.sideMenu ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.sideMenu li {
  width: 100%;
}

.sideMenu a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-base-font);
  padding: 10px 0;
  border-bottom: 1px solid var(--list-background);
}

.sideMenu a .label {
  display: none;
}

button.closeNav {
  display: none;
}

.container {
  display: flex;
  flex: 1;
  margin: auto;
  //  TODO: Apply proper specificity. Keeping this only for internal review.
  padding: 0 !important;
  width: 100%;
}

.columnLeft {
  width: 80px;
  display: inline-flex;
  flex-direction: column;
}

.columnRight {
  width: 100%;
  display: inline;
  background: var(--list-background);
}

@media (max-width: 768px) {
  .main {
    //  TODO: Apply proper specificity. Keeping this only for internal review.
    padding: 0 !important;
  }

  .columnLeft {
    display: none;
  }
}
