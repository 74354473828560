.wrap-content-container {
  min-height: calc(100vh - 180px);
}

.wrap-content {
  width: 100%;
  position: relative;
}

.wrap-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.heading {
  text-align: left;
  margin-bottom: 1.5rem;
}

.contact-container {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.contact-content > div {
  width: 44%;
  margin: 0 0 0 0;
  text-align: left;
}

.contact-content h2 {
  color: var(--color-primary);
}

.contact-content p {
  color: var(--color-primary);
  font-size: 15px;
  line-height: 1.57;
}

.contact-content .form-content {
  width: 50%;
  border-radius: 2px;
  filter:drop-shadow(10px 10px 1px #fdd1ce);
  background-color: var(--color-white);
  padding: 2.5rem;
  margin: 0;
  border-bottom: 10px solid transparent;
  border-image: url(../../../assets/images/border-white.png) 50 round;
  background-clip: padding-box;
}

.contact-content .form-content label{
  color: var(--color-primary);
}

.contact-content .form-content button{
  width: 10.3rem;
  height: 2.5rem;
}

input[type='text'] {
  width: 100%;
  padding: 12px 20px;
  margin: 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--color-pale-blue);
}

input[type='email'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--color-pale-blue);
}

textarea {
  width: 100%;
  height: 200px;
  margin: 99px 0;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--color-pale-blue);
  font-size: 16px;
  resize: none;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 0;
  }

  .wrap-content-container {
    min-height: 85vh;
  }
  .contact-content {
    max-width: 1024px;
    height: 100%;
    margin: 0 2rem;
    display: Flex;
    flex-direction: column;
  }
  .contact-content > div {
    width: 100%;
    margin: 0 2rem 0 0;
    text-align: left;
  }
  .contactInfo h2,
  p {
    width: 100%;
    text-align: center;
  }
  .contact-content p {
    width: 100%;
    text-align: center;
    padding: 0 2rem;
  }
  .contact-content .form-content {
    padding: 2rem 1.5rem;
    width: 100%;
  }
}
@media(max-width: 1024px) {
  .contact-content{
    margin: 0;
  }
}
@media (max-width: 375px) {
  .wrap-content-container {
    min-height: 100vh;
  }
  .contact-content {
    margin: 0 1rem;
  }
  .contact-content .form-content {
    padding: 2rem 1.5rem;
    width: 100%;
  }
}
