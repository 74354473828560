.signup {
  text-align: center;
}

.card {
  flex: 1;
  padding: 20px 40px;
}

.link {
  text-decoration: none;
}

.button {
  margin: 20px auto;
}

.list {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-wrap: wrap
}

.item {
  display: flex;
  flex-flow: row wrap;
  line-height: 1.5em;
  padding: 10px 0;
  flex-grow: 2;
  flex-basis: 0;
}

.item.email {
  flex-grow: 3;
  flex-basis: 0;
}
.item.action {
  flex-grow: 1;
  flex-basis: 0;
}
