@import '../../styles/generic.scss';

.container {
  max-width: 800px;
  min-height: 70vh;
  margin: 0 auto 2rem;
  padding: 0 20px;

  @include lg {
    padding: 0;
  }
}
h2
{text-align: center;}
h2,h3,li
{    
color: var(--color-primary);
}
ol li
{
line-height:1.75;
padding: 0 0 0 0.5rem;
}
ol 
{
  padding: 0 0 0 1rem;
  ol {padding: 0 0 0 3rem;}
}

.content {
  line-height: 1.5;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 4rem;
}
