@import '../../styles/generic.scss';

.addItem {
  padding: 0 24px;
}

.messageContent {
  display: flex;
  align-items: center;
  background-color: var(--color-success-background);
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 4px;
}

.messageContent span {
  margin: 0 20px;
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid var(--color-success-dark);
}

.messageContentHeading {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-success-dark);
}

.messageContentHeading div {
  text-align: left;
  margin: 0;
  padding-left: 0;
}

.messageContentSubHeading {
  font-size: 12px;
  color: var(--color-success-dark);
}

.addItemHeader {
  display: none;

  @include md {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-black);
    padding: 24px 0 20px;
    border-bottom: 1px solid var(--color-pale-blue);
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }
}

.addItemContent {
  padding-top: 23px;
}

.formActions {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid var(--color-pale-blue);
  padding: 20px 0;

  @include md {
    flex-direction: row-reverse;
  }
}

.actionButton {
  width: 100%;

  @include md {
    width: 168px;
  }
}

.submitButton {
  @extend .actionButton;

  @include sm {
    margin-bottom: 16px;
  }

  @include md {
    margin-right: 16px;
  }
}

.cancelButton {
  @extend .actionButton;
  border: 1px solid var(--color-grey-04);
  color: var(--color-base-font);
  background-color: transparent;
}

.addPhotoItemModal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;

  & > .cancelButton {
    background-color: var(--color-white);
    color: var(--color-cancel);
    border: 0;
    border-radius: 4px;
    z-index: 999;
  }
}

.fileCapture {
  background-color: var(--color-white);
  width: 100%;
  display: block;
  border: 0;
  color: var(--color-base-font);
  text-align: left;
  padding: 0 16px;
  overflow: hidden;
  border-radius: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-grey-07);
  }
}

.fileCaptureContainer {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}

.photoImageGrid {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 66px;
    height: 66px;
    padding: 5px;
    display: inline-block;
    position: relative;
    margin-right: 16px;

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
    }

    &:hover .removeImage {
      display: inline-block;
    }
  }
}

.photoImage {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.removeImage {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--color-white);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 999;
  display: none;
  border: 0;
  line-height: 50%;
  text-align: center;
  padding: 0;
}

.addPhotoButton {
  width: 66px;
  height: 66px;
  border: 1px dashed var(--color-secondary);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 0;
  }
}

.removePhotoModal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
  padding: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &Header {
    margin: 0 0 16px;
  }
}

.removeActions {
  display: flex;
  justify-content: space-between;

  & > button {
    width: 48%;
  }
}

.deleteButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-cancel);
  border-radius: 4px;
  color: var(--color-cancel);

  svg {
    stroke: currentColor;
  }
}

.serialNumberModal {
  @extend .removePhotoModal;
  padding: 0;

  &Header {
    text-align: center;
    position: relative;
    border-bottom: 1px solid var(--color-grey-06);
    padding: 17px 0;

    & > svg {
      position: absolute;
      top: 17px;
      right: 24px;
    }
  }

  .title {
    margin: 0;
  }

  &Content {
    padding: 24px;
  }

  &UploadButton {
    @extend .fileCapture;

    border: 1px solid var(--color-secondary);
    border-radius: 4px;
  }

  &Button {
    border: 1px solid var(--color-secondary);
    text-align: left;
    background-color: var(--color-white);
    color: var(--color-base-font);
    height: 50px;
    margin: 16px 0 24px;
    justify-content: unset;
    padding-left: 16px;
    font-size: 14px;

    & > svg {
      margin-right: 10px;
    }
  }

  &Input {
    background-color: var(--color-white);
    height: 48px;
    border: 1px solid var(--color-pale-blue);
    border-radius: 4px;
    width: 100%;
    margin-bottom: 24px;
  }
}

.deletePhotoModal {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
}

.deletePhotoModalContainer {
  background-color: var(--color-white);
  border-radius: 4px;
  padding: 24px;
  width: 360px;

  @media (max-width: 768px) {
    width: 100vw;
  }
}

.deletePhotoModalTitle {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
}

.deletePhotoModalSubtitle {
  color: var(--color-base-font);
  display: block;
  font-size: 14px;
  line-height: 1.4;
  margin: 24px 0;
}
