@import '../../styles/breakpoint';

.outer {
  width: 100%;
  margin: 0 auto;
  padding: 0 5px
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.displayLoggedOut {
  display: none;
}

.displayLoggedIn {
  display: block;
}

.logo img {
  width:100px;
}

.dashboardDropdown {
  width: 200px;
  height: 40px;
  display: inline-flex;
  justify-content: space-around;
  background: none;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0;
  border-radius: 0;
  margin-right: 20px;
}

.myAccountButton {
  width: 140px;
  height: 40px;
  display: inline-flex;
}

.links {
  width: 61px;
  height: 14px;
  font-size: 14px;
  color: var(--color-white);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  padding-right: 50px;
}

.icon {
  width: 16px;
  margin-right: 10px;
  color: var(--color-text-light);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 14px;
  color: var(--color-text-light);
}

.displayLoggedIn {
  display: none;
}

@media (max-width: $screen-md-min) {
  .outer {
    max-width: $screen-md-min;
  }

  .logo {
    margin: 0 auto;
  }

  .displayLoggedIn {
    display: block;
  }

  .container {
    margin: 0 20px;
  }

  .userBtn {
    height: 100%;
    width: 100%;
    color: var(--color-primary);
  }

  .iconButton {
    color: var(--color-primary);
  }

  .links {
    display: none;
  }

  .dashboardDropdown,
  .myAccountButton,
  .loginButton {
    display: none;
  }
}
