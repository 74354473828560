.status {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.badge {
  padding: 5px 16px;
  border-radius: 50px;
  margin-right: 16px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.success {
  color: var(--color-success-dark);
  background: var(--color-success-light);
}

.error {
  color: var(--color-error-dark);
  background: var(--color-error-light);
}

@media (max-width: 768px) {
  .badge {
    padding: 0 10px;
    margin-right: 8px;
  }
}

@media (max-width: 576px) {
  .status {
    justify-content: space-between;
  }
}
