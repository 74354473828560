@import '../../../styles/breakpoint';

.container {
  max-width: $screen-xl-min;
  margin: 5% auto;
  width: 100%;
  display: flex;
}

.firstTitle {
  max-width: 90%;
  text-align: left;
  font-size: 5em;
}

.title {
  max-width: 90%;
  text-align: left;
  font-size: 8.6em;
}

.reducedTitle {
  margin-bottom: 0%;
}

.green {
  color: var(--color-primary);
}

.white {
  color: var(--color-secondary);
}

.body {
  line-height: 1.8;
}

.margin {
  margin-top: 30%;
  margin-bottom: 5%;
}

.titleMargin {
  margin-bottom: 10%;
}

.heroImage {
  width: 100%;
}

.paragraph {
  margin: 0;
  padding: 0;
}

.content {
  width: 50%;
}

.buttonContainer {
  button {
    margin-bottom: 2%;
  }

  display: flex;
}

.buttonColumn {
  padding-right: 2%;
}

@media only screen and (max-width: $screen-md-min) {
  .container {
    max-width: $screen-md-min;
    margin: 5% 0;
    flex-direction: column;
  }

  .content {
    width: 100%;
    padding: 0 20px;
  }

  .heroImage {
    width: 100%;
  }

  .title {
    font-size: 4em;
  }

  .reducedTitle {
    max-width: 100%;
  }
  
  .buttonContainer {

    flex-direction: column;
    margin-bottom: 50px;

    .content {
      padding: 0;

      button {
        margin-bottom: 5%;
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .title {
    font-size: 3.5em;
  }
}